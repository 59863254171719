// extracted by mini-css-extract-plugin
export var black = "#000";
export var copper = "#b87333";
export var dark = "#1d2129";
export var elephant = "#1c3049";
export var error = "#ff1f1f";
export var goldTips = "#e6b80b";
export var greyRegent = "#86909c";
export var mercury = "#e5e6eb";
export var mercury30 = "rgba(229,230,235,.3)";
export var palladium = "#e5e6eb";
export var pumpkinOrange = "#f59c1a";
export var silver = "silver";
export var silverSand = "silver";
export var success = "#008001";
export var white = "#fff";
export var yellowFuel = "#eaa22b";